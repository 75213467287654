import "./vitesse.scss";

import * as dompack from "dompack";
import * as ww from '@mod-ww/webdesigns/template/ww';

dompack.onDomReady(async() => {
  new ww.WWForms();
  console.log(`isLoggedIn(): `, window.vitesseSiteAPI.isLoggedIn());
  console.log(`getLoginStatus(): `, window.vitesseSiteAPI.getLoginStatus());
  console.log(`getJSONContentURL(): `, window.vitesseSiteAPI.getJSONContentURL());
  console.log(`origins: `, window.vitesseSiteAPI.origins);

  const data = await fetchJSON();
  console.log(data);
  window.data = data;

  const pageTitle = data && data.seo ? (data.seo.title === '' ? data.title : data.seo.title) : '';
  const pageDescription = data && data.seo ? data.seo.description : '';
  console.log({pageTitle,pageDescription});

  testGetJSONContentURL();
});

async function fetchJSON() {
  return await (await fetch(window.vitesseSiteAPI.getJSONContentURL())).json();
}

// FIXME: move to unit tests
function testGetJSONContentURL() {
  [// WebHare local NL
   { url: 'http://localhost:8001/vitesse/teams/eerste-selectie/wedstrijden/',
     content: 'http://localhost:8001/vitesse/sitecontent/vitesse/teams/eerste-selectie/wedstrijden/content.json',
   },
   // WebHare local EN
   { url: 'http://localhost:8001/vitesse/en/testpage/',
     content: 'http://localhost:8001/vitesse/en/sitecontent/vitesse-en/testpage/content.json',
   },
   // WebHare test NL
   { url: 'https://api-vitesse-staging.kemari.dev/teams/eerste-selectie/wedstrijden/',
     content: 'https://api-vitesse-staging.kemari.dev/sitecontent/vitesse/teams/eerste-selectie/wedstrijden/content.json',
   },
   // WebHare test EN
   { url: 'https://api-vitesse-staging.kemari.dev/en/testpage/',
     content: 'https://api-vitesse-staging.kemari.dev/en/sitecontent/vitesse-en/testpage/content.json',
   },
   // frontend dev local NL
   { url: 'http://localhost:3000/teams/eerste-selectie/wedstrijden/',
     content: 'http://localhost:8001/vitesse/sitecontent/vitesse/teams/eerste-selectie/wedstrijden/content.json',
   },
   // frontend dev local EN
   { url: 'http://localhost:3000/en/testpage/',
     content: 'http://localhost:8001/vitesse/en/sitecontent/vitesse-en/testpage/content.json',
   },
   // frontend dev test NL
   { url: 'https://vitesse-staging.kemari.dev/teams/eerste-selectie/wedstrijden/',
     content: 'https://api-vitesse-staging.kemari.dev/sitecontent/vitesse/teams/eerste-selectie/wedstrijden/content.json',
   },
   // frontend dev test EN
   { url: 'https://vitesse-staging.kemari.dev/en/testpage/',
     content: 'https://api-vitesse-staging.kemari.dev/en/sitecontent/vitesse-en/testpage/content.json',
   },
   // frontend dev temp test NL
   { url: 'https://vitesse-staging-2.kemari.dev/teams/eerste-selectie/wedstrijden/',
     content: 'https://api-vitesse-staging.kemari.dev/sitecontent/vitesse/teams/eerste-selectie/wedstrijden/content.json',
   },
   // frontend dev temp test EN
   { url: 'https://vitesse-staging-2.kemari.dev/en/testpage/',
     content: 'https://api-vitesse-staging.kemari.dev/en/sitecontent/vitesse-en/testpage/content.json',
   },
  ].forEach(test => {
    const jsonContentURL = window.vitesseSiteAPI.getJSONContentURL(test.url);
    if (jsonContentURL !== test.content) {
      throw `Invalid getJSONContentURL result for:\n${test.url}.\n\nGot:\n${jsonContentURL}\n\nexpected:\n${test.content}`;
    } else {
      // console.log(`${test.url}\n=>\n${jsonContentURL}`);
    }
  });
}
