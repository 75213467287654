import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';

// loaded with dompack.register(".ww-p-overview", node => new PageOverview(node));
export default class OverviewPage {
  // node = the DOM element containing the overview render (ww-p-overview)
  constructor(node) {
    this.node = node;
    this.filterInputs = Array.from(this.node.querySelectorAll('.c-filters input, .c-filters select'));
    this.gridItems = node.querySelectorAll('.js-grid-item');

    if(!whintegration.config.islive && (typeof console != 'undefined'))
      this.debug = console.log.bind(console);
    else
      this.debug = function(message) {};

    // setup filters object
    this.filters = {
      // filter1: [],
      // filter2: [],
    };

    // ADDME: we probably need this if we ever have filters in a widget overview
    // const eFiltersContainer = this.node.querySelector('.js-filters');
    // const dataFilters = eFiltersContainer.dataset.filters ? JSON.parse(eFiltersContainer.dataset.filters) : [];
    // if (dataFilters.length)
    //   whintegration.config.obj['overviewpage_filters'] = dataFilters;

    this.debug('whfilters', whintegration.config.obj.overviewpage_filters);

    if (whintegration.config.obj.overviewpage_filters) {
      for (const filter of whintegration.config.obj.overviewpage_filters) {
        this.filters[filter.name] = [];
      }
    }

    this.debug('filters:', this.filters, 'items:', this.gridItems);

    // this.$searchInput = whintegration.config.obj.textsearch ? $('.js-overview-search-text') : [];

    this._bindEventListeners();

    // if (dompack.debugflags.debug) this._enableDebug();
  }

  _bindEventListeners() {
    for (const input of this.filterInputs) {
      this.debug('bind event listener', input);
      input.addEventListener('change', this._handleChange.bind(this));
    }

    // if (this.$searchInput.length) {
    //   this.$searchInput.on('input', this._handleChange.bind(this));
    // }
  }

  setFilters() {
    for (const filter of whintegration.config.obj.overviewpage_filters) {
      // reset the filter
      this.filters[filter.name] = [];

      // gather inputs of this filter
      const inputs = this.node.querySelectorAll(`input[name="${filter.name}"]`) || [];
      this.debug('inputs', inputs);

      // add the checked filters
      for (const input of inputs) {
        if (input.checked) {
          this.debug(`Added filter for "${filter.name}" with value "${input.value}"`);
          this.filters[filter.name].push(parseInt(input.value) || input.value);
        }
      }

      // same for selects
      const elSelects = this.node.querySelectorAll(`select[name="${filter.name}"]`) || [];
      // dompack.qSA(`.js-filter-category[data-name="${filter.name}"] select`) || [];
      this.debug('selects', elSelects);

      // add selects with a value
      for (const elSelect of elSelects) {
        const selectValue = elSelect.options[elSelect.selectedIndex].value;
        if (selectValue) {
          this.debug(`Added filter for "${filter.name}" with value "${selectValue}"`);
          this.filters[filter.name].push(parseInt(selectValue) || selectValue);
        }
      }

      this.debug('setFilters', this.filters);
    }
  }

  // Change event
  _handleChange(evt) {
    this.debug('handle change for element', evt.target);
    this.filter();
  }

  // Filter the items
  filter() {
    console.clear();
    this.debug('hasActiveFilters', this.hasActiveFilters());
    if (this.hasActiveFilters()) {
      this.setFilters();
      for (const item of this.gridItems)
        item.hidden = !this.itemPassesFilters(item);
    } else {
      this.debug('no filter, show everything');
      for (const item of this.gridItems)
        item.hidden = false;
    }
  }

  // hasActiveFilters if there's at least one <input> checked or a <select> with a value
  hasActiveFilters() {
    return this.filterInputs.some(eFilterInput => _isFilterEnabled(eFilterInput)); // one or more filters checked/selected
    // return   (this.$searchInput.length > 0 && this.$searchInput.val() !== '') // has text search field and filled in
    //       || (this.filterInputs.some(eFilterInput => isFilterEnabled(eFilterInput))); // one or more filters checked/selected
  }

  // Determine whether an element passes the current filters.
  itemPassesFilters(element) {
    if (!element.classList.contains('js-grid-item')) {
      return false;
    }

    for (const filter of whintegration.config.obj.overviewpage_filters) {
      const lookingForIds = this.filters[filter.name];
      if (lookingForIds.length == 0) {
        this.debug('skip', filter.name);
        continue; // not filtering on this one, so skip
      }

      this.debug(`------------ Filtering grid item ${element}, "${filter.name}", for values: `, lookingForIds, '------------');

      const itemIds = JSON.parse(element.getAttribute(`data-${filter.name}`));
      if (!itemIds) {
        console.error(`No itemIds found when trying to filter on "${filter.name}". Does the "data-${filter.name}" attribute exist on the element?`, element, 'Filter:', filter);
        return false;
      }

      this.debug(`Value for field "${filter.name}": `, itemIds);

      const foundItems = lookingForIds.filter(x => itemIds.includes(x));
      if (foundItems.length == 0) { // we're filtering but not found? return false, we're done
        this.debug(`❌ Eliminating grid item ${element} because the value is: `, itemIds);
        return false;
      }

      this.debug(`✅ Adding grid item ${element} because the value is: `, itemIds);
    }

    // optionally search by text
    // if (this.$searchInput.length && this.$searchInput.val()) {
    //   this.debug(this.$searchInput.val());
    //   const includesSearchText = element.getAttribute('data-searchtext').toUpperCase().includes(this.$searchInput.val().toUpperCase());
    //   if (!includesSearchText) {
    //     return false;
    //   }
    // }

    return true;
  }

  _enableDebug() {
    // check first checkbox
    dompack.qS('.c-filters input[type="checkbox"]').checked = true;
    const event = new Event('change');
    dompack.qS('.c-filters input[type="checkbox"]').dispatchEvent(event);
  }
}

function _isFilterEnabled(eFilter) {
  if (eFilter.tagName === 'INPUT') {
    return eFilter.checked === true;
  }

  if (eFilter.tagName === 'SELECT') {
    const value = eFilter.options[eFilter.selectedIndex].value;
    return !!value;
  }

  return false;
}
