/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.widget--ctas.widget--hasbgimage {
  padding-top: var(--ww-widget-ctas-hasbgimage-padding-y);
  padding-bottom: var(--ww-widget-ctas-hasbgimage-padding-y);
}

.w-ctas {
  margin: 0 auto;
}
.w-ctas__container {
  display: grid;
  grid-gap: var(--ww-widget-ctas-grid-gap);
}
@media (min-width: 576px) {
  .w-ctas__container--4, .w-ctas__container--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .w-ctas__container--4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) {
  .w-ctas__container--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) {
  .w-ctas__container--2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.w-ctas__imagelinkcontainer {
  max-width: var(--ww-widget-ctas-image-max-width);
}
.w-ctas__imagelink {
  display: block;
  height: 0;
  position: relative;
  padding-bottom: var(--ww-widget-ctas-image-aspect-ratio);
}
.w-ctas__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.w-ctas__text {
  padding: var(--ww-widget-ctas-text-padding);
}
.w-ctas__text > * {
  margin-top: 0;
  margin-bottom: 1em;
}
.w-ctas__text h3 {
  font-size: 1.5rem;
}
.w-ctas__text h4 {
  font-size: 1.125rem;
}
.w-ctas__text .btn {
  margin-top: 1em;
}
.w-ctas__cta-readmore {
  min-width: var(--ww-button-min-width);
}
.w-ctas--blockstype-textbelow .w-ctas__text, .w-ctas--blockstype-bgcolor .w-ctas__text {
  background-color: var(--ww-widget-ctas-text-background-color);
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkcontainer, .w-ctas--blockstype-bgcolor .w-ctas__imagelinkcontainer {
  max-width: 100% !important;
}
.w-ctas--blockstype-bgimage .w-ctas__col-inner, .w-ctas--blockstype-bgcolor .w-ctas__col-inner {
  position: relative;
  height: calc(100% - 2 * var(--ww-widget-ctas-text-padding));
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkcontainer, .w-ctas--blockstype-bgcolor .w-ctas__imagelinkcontainer {
  margin-bottom: 0 !important;
  height: 100%;
}
.w-ctas--blockstype-bgimage .w-ctas__text, .w-ctas--blockstype-bgcolor .w-ctas__text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  pointer-events: none;
}
.w-ctas--blockstype-bgimage .w-ctas__text > *:first-child, .w-ctas--blockstype-bgcolor .w-ctas__text > *:first-child {
  margin-top: 0;
}
.w-ctas--blockstype-bgimage .w-ctas__text > *:last-child, .w-ctas--blockstype-bgcolor .w-ctas__text > *:last-child {
  margin-bottom: 0;
}
.w-ctas--blockstype-bgimage .w-ctas__text .btn, .w-ctas--blockstype-bgcolor .w-ctas__text .btn {
  display: none;
}
.w-ctas--blockstype-textbelow .w-ctas__imagelink[href], .w-ctas--blockstype-bgimage .w-ctas__imagelink[href] {
  overflow: hidden;
}
@media (hover: hover) {
  .w-ctas--blockstype-textbelow .w-ctas__imagelink[href]:hover .w-ctas__image, .w-ctas--blockstype-bgimage .w-ctas__imagelink[href]:hover .w-ctas__image {
    transform: var(--ww-widget-ctas-image-transform-hover);
  }
}
.w-ctas--blockstype-textbelow .w-ctas__image, .w-ctas--blockstype-bgimage .w-ctas__image {
  transition: transform 0.3s;
}
.w-ctas--blockstype-textbelow .w-ctas__col {
  border-radius: var(--ww-widget-ctas-image-border-radius);
  box-shadow: var(--ww-widget-ctas-col-box-shadow);
  overflow: hidden;
}
.w-ctas--blockstype-textbelow .w-ctas__col-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.w-ctas--blockstype-textbelow .w-ctas__text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.w-ctas--blockstype-textbelow .w-ctas__cta-readmorecontainer {
  margin-top: auto;
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkoverlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ww-widget-ctas-image-overlay);
  transition: opacity 0.3s;
}
@media (hover: hover) {
  .w-ctas--blockstype-bgimage .w-ctas__imagelinkoverlay:hover {
    opacity: var(--ww-widget-ctas-image-overlay-opacity-hover);
  }
}
.w-ctas--blockstype-bgimage .w-ctas__text > * {
  color: var(--ww-widget-ctas-text-color);
}
.w-ctas--blockstype-bgimage .w-ctas__imagelink {
  border-radius: var(--ww-widget-ctas-image-border-radius);
}
.w-ctas--blockstype-bgcolor .w-ctas__text {
  justify-content: flex-start;
  text-decoration: none;
  position: static;
  height: auto;
  min-height: 100%;
  color: inherit;
  pointer-events: auto;
  border-radius: var(--ww-widget-ctas-image-border-radius);
  transition: background 0.3s;
}
.w-ctas--blockstype-bgcolor .w-ctas__text p {
  color: rgba(25, 25, 89, 0.6);
}
@media (hover: hover) {
  .w-ctas--blockstype-bgcolor .w-ctas__text:hover {
    background: var(--ww-widget-ctas-text-background-color-hover);
  }
}

.w-ctas--overwrite-aspectratio .w-ctas__imagelink {
  height: auto;
  padding-bottom: 0;
}