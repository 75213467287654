import '@mod-ww/webdesigns/template/css/properties.scss';
import * as dompack from 'dompack';
import '@mod-system/js/wh/integration';
import '@mod-publisher/js/richcontent/all';
import * as whintegration from '@mod-system/js/wh/integration';
import { SplitDateField, SplitTimeField } from '@mod-publisher/js/forms/fields/splitdatetime';
window.whintegration = whintegration;

// widgets
import '@mod-ww/widgets/widgets.es';

import './js/forms';

import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";
import * as dialogapi from 'dompack/api/dialog';

// components
import '@mod-ww/components/header/header';
import * as dialog from 'dompack/components/dialog';
import CookieWall from '@mod-ww/components/cookiewall/';
import '@mod-ww/components/arrow/arrow.scss';

// pages
import PageOverview from '@mod-ww/pages/overview/overview';

openLinksInNewWindow({ extensions: ['pdf'] });
googleRecaptcha.setupGoogleRecaptcha();
dialogapi.setupDialogs(options => dialog.createDialog('ww-dialog', options));

/*

Initialize:

dompack.onDomReady(() => {
  new WWBasicSite();
  new WWForms();
});

*/

export class WWBasicSite {
  constructor({ addScrollClassToHTML = 100 } = {}) {
    dompack.register(".ww-p-overview", node => new PageOverview(node));

    window.addEventListener('scroll', ev => onScroll(addScrollClassToHTML), window.supportsPassive ? { passive: true } : false);
    onScroll(addScrollClassToHTML);

    if (whintegration.config.site.loadmsocookiewall) {
      const cookieWall = new CookieWall;

      const consentTitle = cookieWall.getCookieWallType() === 'acceptdecline'
        ? `Om deze embedded content (video's, Google Maps) te zien, moet je cookies accepteren.`
        : `Om deze embedded content (video's, Google Maps) te zien, moet je marketing cookies accepteren.`;

      const consentButton = cookieWall.getCookieWallType() === 'acceptdecline'
        ? `Accepteer cookies`
        : `Pas je cookievoorkeuren aan`;

      cookieWall.addConsentOverlayIfNeeded({ title: consentTitle, editSettingsButtonTitle: consentButton });
    } else {
      for (const el of dompack.qSA('.wh-video--activate')) {
        el.click();
      }
    }

    new WWForms();
  }
}

export class WWForms {
  constructor({
    splitDateField = true,
    splitTimeField = true,
    convertButtonsToBootstrapButtons = false,
    bootstrapButtonClasses = ['btn'],
  } = {}) {
    if (splitDateField)
      dompack.register('.wh-form__date', node => new SplitDateField(node));

    if (splitTimeField)
      dompack.register('.wh-form__time', node => new SplitTimeField(node));

    if (convertButtonsToBootstrapButtons) {
      for (const button of dompack.qSA('.wh-form__button')) {
        for (const cls of bootstrapButtonClasses) {
          button.classList.add(cls);
        }
      }
    }
  }
}

function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

function onScroll(offset = 100) {
  let scrollY = getScrollY();
  dompack.toggleClass(document.documentElement, 'is-scrolling', scrollY > offset);
}
