.wh-form__dateinputgroup,
.wh-form__timeinputgroup {
  display: flex;
  flex-flow: row;
  align-items: baseline;
  border: 1px solid #cbcbcb;
  transition: all 0.3s;
  border-radius: 2px;
  height: 38px;
  padding: 0;
}
.wh-form__dateinputgroup:focus, .wh-form__dateinputgroup:hover, .wh-form__dateinputgroup.focus,
.wh-form__timeinputgroup:focus,
.wh-form__timeinputgroup:hover,
.wh-form__timeinputgroup.focus {
  border-color: #808080;
  outline: none;
}
.wh-form__dateinputgroup__line,
.wh-form__timeinputgroup__line {
  display: inline-flex;
  position: relative;
  width: 45px;
  height: 100%;
  white-space: nowrap;
}
.wh-form__dateinputgroup__line input,
.wh-form__timeinputgroup__line input {
  border: 0 none !important;
  min-height: 30px !important;
  width: 100%;
  max-width: 100%;
  height: 100% !important;
  padding: 0 8px !important;
  text-align: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}
.wh-form__dateinputgroup__line input::-webkit-outer-spin-button, .wh-form__dateinputgroup__line input::-webkit-inner-spin-button,
.wh-form__timeinputgroup__line input::-webkit-outer-spin-button,
.wh-form__timeinputgroup__line input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.wh-form__dateinputgroup__line.wh-form__dateinputgroup__year,
.wh-form__timeinputgroup__line.wh-form__dateinputgroup__year {
  width: 60px;
}
.wh-form__dateinputgroup__line:after,
.wh-form__timeinputgroup__line:after {
  display: block;
  padding: 0 3px;
  position: absolute;
  right: -5px;
  top: 6px;
  z-index: 1;
  font-size: 16px;
}
.wh-form__dateinputgroup__line:last-child:after,
.wh-form__timeinputgroup__line:last-child:after {
  display: none;
}

/* FIXME
   are we sure about this styling?
   what is .focus doing here ?

  .wh-form__fieldgroup--error .wh-form__dateinputgroup
, .wh-form__fieldgroup--error .wh-form__timeinputgroup
{
  border-color: #cd202c;
}

  input[disabled] + .wh-form__dateinputgroup
, input[disabled] + .wh-form__timeinputgroup
, input[disabled] + .wh-form__dateinputgroup:hover
, input[disabled] + .wh-form__timeinputgroup:hover
, input[disabled] + .wh-form__dateinputgroup.focus
, input[disabled] + .wh-form__timeinputgroup.focus
{
  padding: 1px;
  border: 1px solid #cbcbcb;
}
*/
.wh-form__dateinputgroup__line:after {
  content: "/";
}

.wh-form__timeinputgroup__line:after {
  top: 3px;
  content: ":";
}