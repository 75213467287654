/* global Swiper */
import './slideshow.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  if (dompack.qSA('.w-slideshow .swiper-container').length === 0) {
    return;
  }

  for (const elSlideshow of dompack.qSA('.w-slideshow')) {
    let elContainer = elSlideshow.querySelector('.swiper-container');

    const numSlides = elContainer.querySelectorAll('.swiper-slide').length;
    if (numSlides < 1) {
      return;
    }

    if (numSlides == 1) {
      elContainer.classList.add('w-slideshow--single-slide');
    }

    const allowSwipe = numSlides > 1;//$(window).width() < 700;

    if (allowSwipe) {
      elSlideshow.classList.add('w-slideshow--has-slides');
    }

    let settings = {
      direction: 'horizontal',
      loop: true,
      speed: 500,
      grabCursor: allowSwipe,
      allowSlidePrev: allowSwipe,
      allowSlideNext: allowSwipe,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    };

    if (elSlideshow.dataset && elSlideshow.dataset.autoplayspeed)
      settings.autoplay = { delay: parseInt(elSlideshow.dataset.autoplayspeed, 10) };

    new Swiper(elContainer, settings);

    // if ($(this).data('autoplayspeed')) {
    //   elContainer.hover(function() {
    //     swiper.autoplay.stop();
    //   }, function() {
    //     swiper.autoplay.start();
    //   });
    // }
  }
});
