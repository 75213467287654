@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import './rtd.css';
.card-img-top {
  object-fit: cover;
}

.wh-widgetpreview body {
  margin: 2rem;
}
.wh-widgetpreview body img {
  max-height: 300px;
}