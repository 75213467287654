/* global $, GLightbox */

import './photoalbum.scss';
import '../../web/glightbox/glightbox.min.css';

import * as dompack from 'dompack';
import GLightbox from '../../web/glightbox/glightbox.min.js';

dompack.onDomReady(() => {
  GLightbox();
});
